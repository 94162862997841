export function senhaExpiradaErro() {
  const senha = this.$v.form.senha;
  return !senha.$dirty ? false : !senha.required ? 'Campo obrigatório' : !senha.senha ? 'A senha não atende aos requisitos' : '';
}
export function confSenhaExpiradaErro() {
  const confSenha = this.$v.form.confSenha;
  return !confSenha.$dirty ? false : !confSenha.required ? 'Campo obrigatório' : !confSenha.matchText ? 'As senhas não conferem' : !confSenha.senha ? 'A senha não atende aos requisitos' : '';
}

export function validaCpf(valor) {
  let cpf = valor.replace(/[.-]/g, '');
  let numeros, digitos, soma, i, resultado, digitos_iguais;
  digitos_iguais = 1;
  if (cpf.length < 11) return false;
  for (i = 0; i < cpf.length - 1; i++)
    if (cpf.charAt(i) != cpf.charAt(i + 1)) {
      digitos_iguais = 0;
      break;
    }
  if (!digitos_iguais) {
    numeros = cpf.substring(0, 9);
    digitos = cpf.substring(9);
    soma = 0;
    for (i = 10; i > 1; i--) soma += numeros.charAt(10 - i) * i;
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(0)) return false;
    numeros = cpf.substring(0, 10);
    soma = 0;
    for (i = 11; i > 1; i--) soma += numeros.charAt(11 - i) * i;
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(1)) return false;
    return true;
  } else return false;
}

export function validaSenha(senha) {
  var re = /(?=^.{8,}$)((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
  return re.test(String(senha));
}
