export default {
  namespaced: true,
  state: {
    campanhas: null,
    //
  },
  mutations: {
    SET_CAMPANHAS(state, campanhas) {
      state.campanhas = campanhas;
    },
  },
  actions: {},
};
