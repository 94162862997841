export default {
  namespaced: true,
  state: {
    totalVendas: null,
    vendasPorCategoria: null,
    //
    rankingRevendasBusca: null,
    rankingRevendasData: {
      total: null,
      participantes: null,
      supertops: null,
      brastemp: null,
      consul: null,
    },
    //
    vendasPorStatusNumeros: null,
    //
    vendasPorStatusGraficoBusca: null,
    vendasPorStatusGraficoData: {
      ativo: null,
      inativo: null,
      preCadastro: null,
      somenteCatalogo: null,
      convideAmigo: null,
    },
    //
    revendasPorCategoriaBusca: null,
    revendasPorCategoriaData: {
      ativo: null,
      inativo: null,
      preCadastro: null,
      somenteCatalogo: null,
      convideAmigo: null,
    },
    //
  },
  mutations: {
    SET_TOTAL_VENDAS(state, totalVendas) {
      state.totalVendas = totalVendas;
    },
    SET_VENDAS_POR_CATEGORIA(state, vendasPorCategoria) {
      state.vendasPorCategoria = vendasPorCategoria;
    },
    //
    SET_RANKING_REVENDAS_BUSCA(state, rankingRevendasBusca) {
      state.rankingRevendasBusca = rankingRevendasBusca;
    },
    SET_RANKING_REVENDAS_DATA(state, rankingRevendasData) {
      state.rankingRevendasData[rankingRevendasData.obj] = rankingRevendasData.data;
    },
    SET_RANKING_REVENDAS_APAGAR(state) {
      state.rankingRevendasData.total = null;
      state.rankingRevendasData.participantes = null;
      state.rankingRevendasData.supertops = null;
      state.rankingRevendasData.brastemp = null;
      state.rankingRevendasData.consul = null;
    },
    //
    SET_VENDAS_POR_STATUS_NUMEROS(state, vendasPorStatusNumeros) {
      state.vendasPorStatusNumeros = vendasPorStatusNumeros;
    },
    //
    SET_VENDAS_POR_STATUS_GRAFICO_BUSCA(state, vendasPorStatusGraficoBusca) {
      state.vendasPorStatusGraficoBusca = vendasPorStatusGraficoBusca;
    },
    SET_VENDAS_POR_STATUS_GRAFICO_DATA(state, vendasPorStatusGraficoData) {
      state.vendasPorStatusGraficoData[vendasPorStatusGraficoData.obj] = vendasPorStatusGraficoData.data;
    },
    SET_VENDAS_POR_STATUS_GRAFICO_APAGAR(state) {
      state.vendasPorStatusGraficoData.ativo = null;
      state.vendasPorStatusGraficoData.inativo = null;
      state.vendasPorStatusGraficoData.preCadastro = null;
      state.vendasPorStatusGraficoData.somenteCatalogo = null;
      state.vendasPorStatusGraficoData.convideAmigo = null;
    },
    //
    SET_REVENDAS_POR_CATEGORIA_BUSCA(state, revendasPorCategoriaBusca) {
      state.revendasPorCategoriaBusca = revendasPorCategoriaBusca;
    },
    SET_REVENDAS_POR_CATEGORIA_DATA(state, revendasPorCategoriaData) {
      state.revendasPorCategoriaData[revendasPorCategoriaData.obj] = revendasPorCategoriaData.data;
    },
    SET_REVENDAS_POR_CATEGORIA_APAGAR(state) {
      state.revendasPorCategoriaData.ativo = null;
      state.revendasPorCategoriaData.inativo = null;
      state.revendasPorCategoriaData.preCadastro = null;
      state.revendasPorCategoriaData.somenteCatalogo = null;
      state.revendasPorCategoriaData.convideAmigo = null;
    },
  },
  actions: {},
};
