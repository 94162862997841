import Vue from 'vue';
import Vuex from 'vuex';
import user from '@/store/user.js';
import dadosPesquisa from '@/store/dadosPesquisa.js';
import usuarios from '@/store/usuarios.js';
import vendas from '@/store/vendas.js';
import pontuacao from '@/store/pontuacao.js';
import produtividade from '@/store/produtividade.js';
import engajamento from '@/store/engajamento.js';
import atendimento from '@/store/atendimento.js';
import campanhas from '@/store/campanhas.js';
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    dadosPesquisa,
    usuarios,
    vendas,
    pontuacao,
    produtividade,
    engajamento,
    atendimento,
    campanhas,
  },
  state: {
    loading: 0,
    menuAtivado: false,
    paginaAtualizada: false,
    alerta: {
      alertaAtivado: false,
      mensagemAlerta: '',
      tipoAlerta: '',
    },
    modal: '',
  },
  mutations: {
    SET_ADICIONAR_LOADING(state) {
      ++state.loading;
    },
    SET_REMOVER_LOADING(state) {
      --state.loading;
    },
    SET_MENU(state, menu) {
      state.menuAtivado = menu;
    },
    SET_PAGINA_ATUALIZADA(state, atualizada) {
      state.paginaAtualizada = atualizada;
    },
    SET_ALERTA(state, alerta) {
      state.alerta.alertaAtivado = alerta.ativado;
      state.alerta.mensagemAlerta = alerta.mensagem;
      state.alerta.tipoAlerta = alerta.tipo;
    },
    SET_MODAL(state, modal) {
      state.modal = modal;
    },
  },
  actions: {},
});
