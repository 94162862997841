import router from '@/router';
//
export default {
  namespaced: true,
  state: {
    estaLogado: false,
    usuario: null,
    idUsuario: null,
    perfilUsuario: null,
    senhaRedefinicao: null,
  },
  mutations: {
    SET_LOGADO(state, logado) {
      state.estaLogado = logado;
    },
    SET_USUARIO(state, dados) {
      state.usuario = dados;
    },
    SET_ID_USUARIO(state, id) {
      state.idUsuario = id;
    },
    SET_ID_PERFIL_USUARIO(state, perfil) {
      state.perfilUsuario = perfil;
    },
    SET_DESLOGAR(state) {
      state.estaLogado = false;
      state.usuario = null;
      state.idUsuario = null;
      state.perfilUsuario = null;
      localStorage.removeItem('token');
      router.push('/login');
    },
    SET_SENHA_REDEFINICAO(state, senha) {
      state.senhaRedefinicao = senha;
    },
  },
  actions: {},
};
