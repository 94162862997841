export default {
  namespaced: true,
  state: {
    regionais: null,
    revendas: null,
    //
    mesPesquisa: null,
    anoPesquisa: null,
    regionalSelecionada: null,
    revendasSelecionadas: null,
    revendasOptions: null,
    //
    primeiraBusca: false,
  },
  mutations: {
    SET_REGIONAIS(state, regionais) {
      state.regionais = regionais;
    },
    SET_REVENDAS(state, revendas) {
      state.revendas = revendas;
    },
    SET_MES_PESQUISA(state, mes) {
      state.mesPesquisa = mes;
      sessionStorage.setItem('mesPesquisa', mes);
    },
    SET_ANO_PESQUISA(state, ano) {
      state.anoPesquisa = ano;
      sessionStorage.setItem('anoPesquisa', ano);
    },
    SET_REGIONAL_SELECIONADA(state, regionalSelecionada) {
      state.regionalSelecionada = regionalSelecionada;
      localStorage.setItem('regionalSelecionada', JSON.stringify(regionalSelecionada));
    },
    SET_REVENDAS_SELECIONADAS(state, revendasSelecionadas) {
      state.revendasSelecionadas = revendasSelecionadas;
      localStorage.setItem('revendasSelecionadas', revendasSelecionadas);
    },
    SET_REVENDAS_OPTIONS(state, revendasOptions) {
      state.revendasOptions = revendasOptions;
      localStorage.setItem('revendasOptions', JSON.stringify(revendasOptions));
    },
    //
    SET_PRIMEIRA_BUSCA(state, primeiraBusca) {
      state.primeiraBusca = primeiraBusca;
    },
  },
  actions: {},
};
