export default {
  namespaced: true,
  state: {
    totalParticipantes: null,
    //
    novosVendedoresBusca: null,
    novosVendedoresData: {
      ativo: null,
      inativo: null,
      preCadastro: null,
      somenteCatalogo: null,
      convideAmigo: null,
    },
    //
    revendasStatusBusca: null,
    revendasStatusData: {
      ativo: null,
      inativo: null,
      preCadastro: null,
      somenteCatalogo: null,
      convideAmigo: null,
    },
  },
  mutations: {
    SET_TOTAL_PARTICIPANTES(state, totalParticipantes) {
      state.totalParticipantes = totalParticipantes;
    },
    //
    SET_NOVOS_VENDEDORES_BUSCA(state, novosVendedoresBusca) {
      state.novosVendedoresBusca = novosVendedoresBusca;
    },
    SET_NOVOS_VENDEDORES_DATA(state, novosVendedoresData) {
      state.novosVendedoresData[novosVendedoresData.obj] = novosVendedoresData.data;
    },
    SET_NOVOS_VENDEDORES_APAGAR(state) {
      state.novosVendedoresData.ativo = null;
      state.novosVendedoresData.inativo = null;
      state.novosVendedoresData.preCadastro = null;
      state.novosVendedoresData.somenteCatalogo = null;
      state.novosVendedoresData.convideAmigo = null;
    },
    //
    SET_REVENDAS_STATUS_BUSCA(state, revendasStatusBusca) {
      state.revendasStatusBusca = revendasStatusBusca;
    },
    SET_REVENDAS_STATUS_DATA(state, revendasStatusData) {
      state.revendasStatusData[revendasStatusData.obj] = revendasStatusData.data;
    },
    SET_REVENDAS_STATUS_APAGAR(state) {
      state.revendasStatusData.ativo = null;
      state.revendasStatusData.inativo = null;
      state.revendasStatusData.preCadastro = null;
      state.revendasStatusData.somenteCatalogo = null;
      state.revendasStatusData.convideAmigo = null;
    },
  },
  actions: {},
};
