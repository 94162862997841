import store from '@/store';
import router from '@/router';
//
export function tokenExpirado(erro, mensagemErro) {
  if (erro.response && erro.response.status == 401) {
    localStorage.removeItem('token');
    router.push('/login');
    return store.commit('SET_ALERTA', {
      ativado: true,
      mensagem: 'Seu login expirou.',
      tipo: 'erro',
    });
  }
  if (erro.response && mensagemErro) {
    return store.commit('SET_ALERTA', {
      ativado: true,
      mensagem: String(erro.response.data.mensagem),
      tipo: 'erro',
    });
  }
}
