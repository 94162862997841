<template>
  <div id="main">
    <transition name="fade" mode="out-in">
      <Loading v-if="loading > 0" />
    </transition>
    <transition name="fade" mode="out-in">
      <Modal v-if="modal != ''" />
    </transition>
    <MensagemAlerta />
    <transition name="fade-left" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
const Loading = () => import('@/components/loading/Loading.vue');
const MensagemAlerta = () => import('@/components/alerta/Alerta.vue');
const Modal = () => import('@/components/modal/_Modal.vue');
export default {
  components: {
    Loading,
    MensagemAlerta,
    Modal,
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
    modal() {
      return this.$store.state.modal;
    },
  },
  watch: {
    loading(val) {
      return val > 0 ? document.querySelector('body').classList.add('block-scroll') : document.querySelector('body').classList.remove('block-scroll');
    },
    modal(val) {
      return val != '' ? document.querySelector('body').classList.add('block-scroll-modal') : document.querySelector('body').classList.remove('block-scroll-modal');
    },
  },
};
</script>

<style lang="scss">
body {
  background: $roxo6;
  transition: background 0.3s;
  &.bg-laranja {
    background: $laranja5;
  }
}
#main {
  height: 100%;
  @media (max-width: 1150px) {
    
  }
}
</style>
