import axios from 'axios';
import store from '@/store';
import VueJwtDecode from 'vue-jwt-decode';
import { _rotasAPI } from '@/services/_caminhosApi';
import { tokenExpirado } from '@/utils/tokenExpirado';
//
export function buscarUsuario(token) {
  return new Promise((resolve, reject) => {
    store.commit('SET_ADICIONAR_LOADING');
    const tokenDecoded = VueJwtDecode.decode(token);
    if (!tokenDecoded) {
      store.commit('SET_REMOVER_LOADING');
      localStorage.removeItem('token');
      return reject();
    }
    axios
      .get(`${_rotasAPI().buscarUsuario}/${tokenDecoded.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        store.commit('user/SET_LOGADO', true);
        store.commit('user/SET_USUARIO', resp.data);
        store.commit('user/SET_ID_USUARIO', resp.data.id);
        store.commit('user/SET_ID_PERFIL_USUARIO', resp.data.office.id);
        resolve(resp);
      })
      .catch((erro) => {
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}
