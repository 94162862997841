export default {
  namespaced: true,
  state: {
    //
    produtividadeMes: null,
    produtividadeRevenda: null,
    //
  },
  mutations: {
    SET_PERCENTUAL_MENSAL_VENDAS_MES(state, ProdutividadeMes) {
      state.produtividadeMes = ProdutividadeMes;
    },
    SET_PERCENTUAL_MENSAL_VENDAS_REVENDA(state, ProdutividadeRevenda) {
      state.produtividadeRevenda = ProdutividadeRevenda;
    },
  },
  actions: {},
};
