import axios from 'axios';
import store from '@/store';
import router from '@/router';
import { buscarUsuario } from '@/services/login/buscarUsuario';
import { _rotasAPI } from '@/services/_caminhosApi';
//
export function enviarLogin(obj, ip) {
  store.commit('SET_ADICIONAR_LOADING');
  const data = {
    cpf: obj.cpf.replace(/[^\d]+/g, ''),
    password: obj.senha,
    ip: ip,
    sistema: 4,
  };
  axios
    .post(_rotasAPI().enviarLogin, data)
    .then((resp) => {
      localStorage.setItem('token', resp.data.token);
      localStorage.setItem('powerbi', JSON.stringify(resp.data.poweBi));
      buscarUsuario(resp.data.token).then(() => {
        router.push('/');
      });
    })
    .catch((erro) => {
      !erro.response
        ? store.commit('SET_ALERTA', {
            ativado: true,
            mensagem: 'Erro ao estabelecer uma conexão.',
            tipo: 'erro',
          })
        : store.commit('SET_ALERTA', {
            ativado: true,
            mensagem: String(erro.response.data.message),
            tipo: 'erro',
          });
    })
    .finally(() => store.commit('SET_REMOVER_LOADING'));
}
