export default {
  namespaced: true,
  state: {
    //
    pontuacaoMesBusca: null,
    pontuacaoMesData: {
      totalDePontos: null,
      vendas: null,
      treinamentos: null,
      campanhas: null,
    },
    pontuacaoRevendaBusca: null,
    pontuacaoRevendaData: {
      totalDePontos: null,
      vendas: null,
      treinamentos: null,
      campanhas: null,
    },
  },
  mutations: {
    SET_PONTUACAO_MES_BUSCA(state, pontuacaoMesBusca) {
      state.pontuacaoMesBusca = pontuacaoMesBusca;
    },
    SET_PONTUACAO_MES_DATA(state, pontuacaoMesData) {
      state.pontuacaoMesData[pontuacaoMesData.obj] = pontuacaoMesData.data;
    },
    SET_PONTUACAO_MES_APAGAR(state) {
      state.pontuacaoMesData.totalDePontos = null;
      state.pontuacaoMesData.vendas = null;
      state.pontuacaoMesData.treinamentos = null;
      state.pontuacaoMesData.campanhas = null;
    },
    //
    SET_PONTUACAO_REVENDAS_BUSCA(state, pontuacaoRevendaBusca) {
      state.pontuacaoRevendaBusca = pontuacaoRevendaBusca;
    },
    SET_PONTUACAO_REVENDAS_DATA(state, pontuacaoRevendaData) {
      state.pontuacaoRevendaData[pontuacaoRevendaData.obj] = pontuacaoRevendaData.data;
    },
    SET_PONTUACAO_REVENDAS_APAGAR(state) {
      state.pontuacaoRevendaData.totalDePontos = null;
      state.pontuacaoRevendaData.vendas = null;
      state.pontuacaoRevendaData.treinamentos = null;
      state.pontuacaoRevendaData.campanhas = null;
    },
  },
  actions: {},
};
