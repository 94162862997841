<template>
  <div id="login">
    <div class="login-container">
      <div class="login-container-logo">
        <img src="@/assets/img/icone/icone-bg-logo.png" alt="+TOP Dash" />
        <p>Bem-vindo ao</p>
        <img src="@/assets/img/logos/topnews-v-n.svg" alt="+TOP Dash" />
      </div>
      <div class="login-container-form">
        <form @submit.prevent class="form">
          <p>CPF:</p>
          <input type="tel" v-mask="'999.999.999-99'" placeholder="CPF" v-model.trim="form.cpf" @blur="$v.form.cpf.$touch()" />
          <p>Senha:</p>
          <div class="login-container-senha">
            <input ref="senha" type="password" placeholder="Senha" v-model.trim="form.senha" @blur="$v.form.senha.$touch()" />
            <i class="icone-olho-senha" :class="{ active: senha }" @click="toggleMostrarSenha('senha')"></i>
          </div>
          <button class="btn-login" :disabled="$v.form.$invalid" @click="enviarLogin">Enviar</button>
          <div class="login-container-form-link">
            <router-link to="esqueci-minha-senha" class="link link-verde">Esqueci minha senha</router-link>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import AwesomeMask from 'awesome-mask';
import { obterIp } from '@/services/login/ip';
import { required } from 'vuelidate/lib/validators';
import { validaCpf } from '@/utils/vuelidate';
export default {
  name: 'Login',
  directives: {
    mask: AwesomeMask,
  },
  data() {
    return {
      senha: false,
      form: {
        cpf: '',
        senha: '',
      },
    };
  },
  computed: {
    modal() {
      return this.$store.state.modal;
    },
  },
  validations: {
    form: {
      cpf: {
        required,
        cpf: validaCpf,
      },
      senha: { required },
    },
  },
  methods: {
    toggleMostrarSenha(ref) {
      if (this.$refs[ref].type == 'password') {
        this[ref] = true;
        this.$refs[ref].type = 'text';
      } else {
        this[ref] = false;
        this.$refs[ref].type = 'password';
      }
    },
    enviarLogin() {
      if (this.form.$invalid) return false;
      obterIp(this.form);
    },
  },
  mounted() {
    if (this.modal != '') {
      this.$store.commit('SET_MODAL', '');
    }
  },
};
</script>

<style lang="scss">
#login {
  display: flex;
  overflow: hidden;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  min-height: 100vh;
  padding: 20px 20px 65px 20px;
  background: $roxo5;
  @media (max-width: 600px) {
    padding: 35px 20px 100px 20px;
  }
  .login-container {
    display: flex;
    width: 100%;
    max-width: 650px;
    margin: 0 auto;
    @media (max-width: 600px) {
      align-items: center;
      flex-direction: column;
    }
    > div {
      width: 50%;
      @media (max-width: 600px) {
        width: 100%;
        max-width: 320px;
      }
    }
  }
  .login-container-logo {
    position: relative;
    padding-top: 30px;
    padding-right: 30px;
    @media (max-width: 600px) {
      max-width: 250px !important;
      margin-bottom: 30px;
      padding-right: 0;
    }
    p {
      font-size: 18px;
      font-weight: 500;
      position: relative;
      margin-bottom: 10px;
      color: $branco;
    }
    @keyframes rotacionar {
      from {
        transform: translate(-50%, -50%) rotate(0deg);
      }
      to {
        transform: translate(-50%, -50%) rotate(360deg);
      }
    }
    img:first-of-type {
      position: absolute;
      top: calc(50% - 50px);
      left: calc(50% - 120px);
      transform: translate(-50%, -50%);
      animation: rotacionar 120s linear infinite;
      @media (max-width: 600px) {
        left: calc(50% - 60px);
      }
    }
    img:last-of-type {
      position: relative;
      max-width: 100%;
    }
  }
  .login-container-form {
    position: relative;
    .login-container-senha {
      position: relative;
      .icone-olho-senha {
        position: absolute;
        display: block;
        width: 15px;
        height: 15px;
        top: 15px;
        right: 15px;
        cursor: pointer;
        border: solid 1px $roxo5;
        border-radius: 75% 15%;
        transform: rotate(45deg);
        &:before {
          content: '';
          display: block;
          position: absolute;
          transform: rotate(-45deg);
          background: $roxo5;
          width: 5px;
          height: 5px;
          border: solid 1px $roxo5;
          border-radius: 50%;
          left: 3px;
          top: 3px;
          transition: 0.3s;
        }
        &:after {
          content: '';
          width: 1px;
          background: $roxo5;
          height: 18px;
          position: absolute;
          top: -2px;
          left: 6px;
          transform: rotate(-5deg);
          opacity: 0;
          visibility: hidden;
          transition: 0.3s;
        }
        &.active {
          &:after {
            opacity: 1;
            visibility: visible;
          }
          &:before {
            opacity: 0.5;
          }
        }
      }
    }
    .btn-login {
      font-size: 15px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 132px;
      height: 42px;
      margin-bottom: 20px;
      cursor: pointer;
      transition: 0.3s;
      border: none;
      border-radius: 25px;
      outline: none;
      background: #bee805;
      &:hover {
        background: darken(#bee805, 5%);
      }
      &:disabled {
        cursor: default;
        background: $cinza1;
      }
      @media (max-width: 600px) {
        margin: 0 auto 20px auto;
      }
    }
    .login-container-form-link {
      padding-left: 5px;
      @media (max-width: 600px) {
        width: 100%;
        padding-left: 0;
        text-align: center;
      }
    }
  }
}
</style>
