export default {
  namespaced: true,
  state: {
    engajamentoDoMes: null,
    engajadosPorRevenda: null,
    //
    desempenhoTreinamentoMes: null,
    desempenhoTreinamentoRevenda: null,
  },
  mutations: {
    SET_ENGAJAMENTO_DO_MES(state, engajamentoDoMes) {
      state.engajamentoDoMes = engajamentoDoMes;
    },
    SET_ENGAJADOS_POR_REVENDA(state, engajadosPorRevenda) {
      state.engajadosPorRevenda = engajadosPorRevenda;
    },
    SET_DESEMPENHO_TREINAMENTO_MES(state, desempenhoTreinamentoMes) {
      state.desempenhoTreinamentoMes = desempenhoTreinamentoMes;
    },
    SET_DESEMPENHO_TREINAMENTO_REVENDA(state, desempenhoTreinamentoRevenda) {
      state.desempenhoTreinamentoRevenda = desempenhoTreinamentoRevenda;
    },
  },
  actions: {},
};
