import axios from "axios";
import store from "@/store";
// Caminhos
import { _rotasAPI } from "@/services/_caminhosApi";
import { enviarLogin } from "@/services/login/login";
// IP
export function obterIp(data) {
  store.commit("SET_ADICIONAR_LOADING");
  axios
    .get(_rotasAPI().ipUsuario)
    .then((resp) => {
      enviarLogin(data, resp.data);
    })
    .finally(() => store.commit("SET_REMOVER_LOADING"));
}
