export default {
  namespaced: true,
  state: {
    totalAtendimento: null,
    totalAtendimentoOcorrencias: null,
    //
  },
  mutations: {
    SET_TOTAL_ATENDIMENTO(state, totalAtendimento) {
      state.totalAtendimento = totalAtendimento;
    },
    SET_TOTAL_ATENDIMENTO_OCORRENCIAS(state, totalAtendimentoOcorrencias) {
      state.totalAtendimentoOcorrencias = totalAtendimentoOcorrencias;
    },
  },
  actions: {},
};
