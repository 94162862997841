export function _rotasAPI() {
  return {
    ipUsuario: 'https://api.ipify.org',
    // Authentication
    enviarLogin: `${process.env.VUE_APP_API_BASE}/Authentication/LoginUsuarioPerfilAcesso`,
    enviarEsqueceuSenha: `${process.env.VUE_APP_API_BASE}/User/ObterDadosDeContatoDoUsuario`,
    enviarCodigoRedefinicao: `${process.env.VUE_APP_API_BASE}/Authentication/EnviarCodigoParaRedefinicaoDeSenha`,
    enviarSenhaRedefinicao: `${process.env.VUE_APP_API_BASE}/User/RedefinirSenhaDoUsuario`,
    // User
    buscarUsuario: `${process.env.VUE_APP_API_BASE}/User/GetUser`,
    // Network
    buscarRevendas: `${process.env.VUE_APP_API_BASE}/Network/ObterNomeRevendasAtivas`,
    buscarRegionais: `${process.env.VUE_APP_API_BASE}/Network/GetAllRegionais`,
    // UsuarioKPI
    buscarTotalParticipantes: `${process.env.VUE_APP_API_BASE}/UsuarioKPI/ObterTotalDeParticipantes`,
    buscarNovosVendedores: `${process.env.VUE_APP_API_BASE}/UsuarioKPI/ObterTotalVendedoresPorStatusMensal`,
    buscarRevendasStatus: `${process.env.VUE_APP_API_BASE}/UsuarioKPI/ObterTotalVendedoresPorStatusXRevenda`,
    // VendaKPI
    buscarTotalVendas: `${process.env.VUE_APP_API_BASE}/VendaKPI/ObterDesempenhoDeVendas`,
    buscarVendasPorCategoria: `${process.env.VUE_APP_API_BASE}/VendaKPI/ObterTotalVendasPorCategoria`,
    buscarRankingDeRevendas: `${process.env.VUE_APP_API_BASE}/VendaKPI/ObterTotalVendasPorRevendaXCategoria`,
    buscarVendasPorStatusNumeros: `${process.env.VUE_APP_API_BASE}/VendaKPI/ObterTotalVendasPorStatus`,
    buscarVendasPorStatusMensal: `${process.env.VUE_APP_API_BASE}/VendaKPI/ObterTotalVendedoresPorStatusMensal`,
    buscarRevendasPorCategoria: `${process.env.VUE_APP_API_BASE}/VendaKPI/ObterTotalVendedoresPorRevendaXStatus`,
    // PontuacaoKPI
    buscarPontuacaoMes: `${process.env.VUE_APP_API_BASE}/PontuacaoUsuarioKPI/ObterTotalPontuacaoUsuarioMensal`,
    buscarPontuacaoRevenda: `${process.env.VUE_APP_API_BASE}/PontuacaoUsuarioKPI/ObterTotalPontuacaoUsuarioPorRevenda`,
    // ProdutividadeKPI
    buscarProdutividadeMes: `${process.env.VUE_APP_API_BASE}/VendaKPI/ObterTotalVendasMaisTopXWhirlpoolPorMes`,
    buscarProdutividadeRevenda: `${process.env.VUE_APP_API_BASE}/VendaKPI/ObterTotalVendasMaisTopXWhirlpoolPorRevenda`,
    // EngajamentoKPI
    buscarEngajamentoDoMes: `${process.env.VUE_APP_API_BASE}/EngajamentoKPI/ObterDesempenhoEngajamento`,
    buscarEngajadosPorRevenda: `${process.env.VUE_APP_API_BASE}/EngajamentoKPI/ObterTotalVendedoresEngajadosPorRevenda`,
    buscarDesempenhoTreinamentoMes: `${process.env.VUE_APP_API_BASE}/EngajamentoKPI/ObterDesempenhoTreinamentoMensal`,
    buscarDesempenhoTreinamentoRevenda: `${process.env.VUE_APP_API_BASE}/EngajamentoKPI/ObterDesempenhoTreinamentoPorRevenda`,
    // AtendimentoKPI
    buscarAtendimento: `${process.env.VUE_APP_API_BASE}/AtendimentoKPI/ObterRelatorioDeLigacoes`,
    buscarAtendimentoOcorrencias: `${process.env.VUE_APP_API_BASE}/AtendimentoKPI/ObterOcorrenciasAvaliadas`,
    // CampanhasKPI
    imagensCampanhas: `${process.env.VUE_APP_API_BASE}/wwwroot/Content/Campaign/`,
    buscarCampanhas: `${process.env.VUE_APP_API_BASE}/CampanhaKPI/ObterCampanhas`,
    // // Aniversario
    buscarCampanhasAniversario: `${process.env.VUE_APP_API_BASE_NEWS}/Campanha/ObterCampanhaDeAniversario/`,
    buscarCampanhasAniversarioEngajamento: `${process.env.VUE_APP_API_BASE}/CampanhaKPI/ObterEngajamentoDaCampanha/`,
    // // Sazonal
    buscarCampanhasSazonais: `${process.env.VUE_APP_API_BASE}/CampanhaKPI/ObterCampanhaSazonal/`,
    buscarCampanhasSazonaisVendasMensais: `${process.env.VUE_APP_API_BASE_NEWS}/Campanha/ObterCampanhaSazonal/`,
    buscarCampanhasSazonaisDesempenho: `${process.env.VUE_APP_API_BASE}/CampanhaKPI/ObterRankingDeProdutosVendidosNaCampanha/`,
    buscarCampanhasSazonaisDesempenhoRevenda: `${process.env.VUE_APP_API_BASE}/CampanhaKPI/ObterDesempenhoDaCampanhaPorRevenda`,
    buscarCampanhasSazonaisRanking: `${process.env.VUE_APP_API_BASE}/CampanhaKPI/ObterTotalVendedoresEngajadosNaCampanhaPorRevenda`,
    // Anual
    buscarCampanhasAnual: `${process.env.VUE_APP_API_BASE_NEWS}/Campanha/ObterCampanhaAnualRankingPorRevenda`,
    buscarCampanhasAnualRanking: `${process.env.VUE_APP_API_BASE_NEWS}/Campanha/ObterCampanhaAnualRankingPorCargo`,
    // Relatorios
    downloadTreinamentos: `${process.env.VUE_APP_API_BASE}/Report/GetGeneralReportTraining`,
    downloadVendas: `${process.env.VUE_APP_API_BASE}/Report/GetGeneralReportSales`,
    downloadPontuacao: `${process.env.VUE_APP_API_BASE}/Report/GeneralReportPunctuation`,
    downloadEngajamento: `${process.env.VUE_APP_API_BASE}/Report/GetGeneralReportUsers`,
  };
}
