<template>
  <main id="main-page" :class="{ 'bg-laranja': trocaCor }">
    <!-- <div class="header-height" v-if="this.$router.currentRoute.path!='/power-bi'"></div> -->
    <!-- <Header v-if="this.$router.currentRoute.path!='/power-bi'" /> -->
    <!--<BoxDataRevenda id="header-mobile" />-->
    <section>
      <transition name="fade-left" mode="out-in">
        <router-view  style="height:100%" />
      </transition>
    </section>
    <!-- <Footer /> -->
  </main>
</template>

<script>
// import { buscarRevendas } from '@/services/_buscarRevendas';
// const Header = () => import('@/components/header/Header.vue');
// const BoxDataRevenda = () => import('@/components/header/BoxDataRevenda.vue');
// const Footer = () => import('@/components/footer/Footer.vue');
export default {
  name: 'Main',
  components: {
    // Header,
    // BoxDataRevenda,
    // Footer,
  },
  data() {
    return {
      trocaCor: false,
    };
  },
  computed: {
    // revendasSelecionada() {
    //   return this.$store.state.dadosPesquisa.revendasSelecionadas && this.$store.state.dadosPesquisa.revendasSelecionadas.length > 0;
    // },
  },
  methods: {
    // buscarRevendas,
    // selecionarRevendas() {
    //   const regionalSelecionadaLocalStorage = localStorage.getItem('regionalSelecionada');
    //   const revendasSelecionadasLocalStorage = localStorage.getItem('revendasSelecionadas');
    //   const revendasOptionsLocalStorage = localStorage.getItem('revendasOptions');
    //   if (regionalSelecionadaLocalStorage) {
    //     this.$store.commit('dadosPesquisa/SET_REGIONAL_SELECIONADA', JSON.parse(regionalSelecionadaLocalStorage));
    //   }
    //   if (revendasSelecionadasLocalStorage && revendasOptionsLocalStorage) {
    //     this.$store.commit('dadosPesquisa/SET_REVENDAS_SELECIONADAS', revendasSelecionadasLocalStorage.split(','));
    //     this.$store.commit('dadosPesquisa/SET_REVENDAS_OPTIONS', JSON.parse(revendasOptionsLocalStorage));
    //   } else {
    //     this.$store.commit('SET_MODAL', 'TrocarRevenda');
    //   }
    // },
    trocaBg(obj) {
      if (obj.titulo == 'Relatórios') {
        this.trocaCor = true;
      } else {
        this.trocaCor = false;
      }
    },
  },
  created() {
    this.selecionarRevendas();
    this.buscarRevendas();
  },
  watch: {
    revendasSelecionada(val) {
      !val ? this.$store.commit('SET_MODAL', 'TrocarRevenda') : '';
    },
    '$route'(to) {
      this.trocaBg(to.meta);
    },
  },
  mounted() {
    this.trocaBg(this.$router.history.current.meta);
  },
};
</script>

<style lang="scss">
#main-page {
  height: 100%;
  background: $roxo6;
  transition: background 0.3s;
  &.bg-laranja {
    background: #f24405;
  }
  @media (min-width: 1151px) {
    overflow: hidden;
  }
  @media (max-width: 1150px) {
    height: 100%;
  }
  .header-height {
    height: 90px;
    width: 100%;
  }
  > section {
    height: 100%;
    box-sizing: border-box;
    @media (max-width: 1150px) {
      min-height: calc(100% - 275px);
      height: 100%;
    }
  }
}
</style>
