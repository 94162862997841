import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';
import Login from '@/views/Login.vue';
import Main from '@/views/_Main.vue';
//
import { buscarUsuario } from '@/services/login/buscarUsuario';
Vue.use(VueRouter);

const routes = [
  {
    name: 'login',
    path: '/login',
    component: Login,
  },
  {
    name: 'esqueci-minha-senha',
    path: '/esqueci-minha-senha',
    component: () => import(/* webpackChunkName: "esqueciSenha" */ '@/views/EsqueciSenha.vue'),
  },
  {
    name: 'redefinir-senha',
    path: '/redefinir-senha',
    component: () => import(/* webpackChunkName: "esqueciSenha" */ '@/views/RedefinirSenha.vue'),
  },
  {
    path: '/',
    component: Main,
    children: [
      {
        path: '/',
        name: 'power-bi',
        component: () => import(/* webpackChunkName: "relatorios" */ '@/views/power-bi/Power-Bi.vue'),
        meta: {
          precisaAutenticacao: true,
          titulo: 'Power-BI',
          pagAnterior: '/relatorios',
        },
      },
      // {
      //   path: '/',
      //   name: 'usuario-ranking-status',
      //   component: () => import(/* webpackChunkName: "usuario" */ '@/views/usuario/RevendasStatus.vue'),
      //   meta: {
      //     precisaAutenticacao: true,
      //     titulo: 'Usuários',
      //     proxPagina: '/total-mensal-novos-vendedores',
      //     funcao: 'buscaRevendasStatus',
      //   },
      // },
      // {
      //   path: '/total-mensal-novos-vendedores',
      //   name: 'total-mensal-novos-vendedores',
      //   component: () => import(/* webpackChunkName: "usuario" */ '@/views/usuario/NovosVendedores.vue'),
      //   meta: {
      //     precisaAutenticacao: true,
      //     titulo: 'Usuários',
      //     pagAnterior: '/',
      //     proxPagina: '/geral-usuarios',
      //     funcao: 'buscaNovosVendedores',
      //   },
      // },
      // {
      //   path: '/geral-usuarios',
      //   name: 'total-participante',
      //   component: () => import(/* webpackChunkName: "usuario" */ '@/views/usuario/TotalParticipantes.vue'),
      //   meta: {
      //     precisaAutenticacao: true,
      //     titulo: 'Usuários',
      //     pagAnterior: '/total-mensal-novos-vendedores',
      //     proxPagina: '/ranking-revendas-categoria',
      //     funcao: 'buscarTotalParticipantes',
      //   },
      // },
      //
      // {
      //   path: '/ranking-revendas-categoria',
      //   name: 'ranking-revendas-categoria',
      //   component: () => import(/* webpackChunkName: "vendas" */ '@/views/vendas/RankingDeRevendas.vue'),
      //   meta: {
      //     precisaAutenticacao: true,
      //     titulo: 'Vendas',
      //     pagAnterior: '/geral-usuarios',
      //     proxPagina: '/vendas-por-categoria',
      //     funcao: 'buscarRankingDeRevendas',
      //   },
      // },
      // {
      //   path: '/vendas-por-categoria',
      //   name: 'vendas-por-categoria',
      //   component: () => import(/* webpackChunkName: "vendas" */ '@/views/vendas/VendasPorCategoria.vue'),
      //   meta: {
      //     precisaAutenticacao: true,
      //     titulo: 'Vendas',
      //     pagAnterior: '/ranking-revendas-categoria',
      //     proxPagina: '/geral-vendas',
      //     funcao: 'buscarVendasPorCategoria',
      //   },
      // },
      // {
      //   path: '/geral-vendas',
      //   name: 'geral-vendas',
      //   component: () => import(/* webpackChunkName: "vendas" */ '@/views/vendas/TotalVendas.vue'),
      //   meta: {
      //     precisaAutenticacao: true,
      //     titulo: 'Vendas',
      //     pagAnterior: '/vendas-por-categoria',
      //     proxPagina: '/vendas-por-status',
      //     funcao: 'buscarTotalVendas',
      //   },
      // },

      // {
      //   path: '/vendas-por-status',
      //   name: 'vendas-por-status',
      //   component: () => import(/* webpackChunkName: "vendas" */ '@/views/vendas/VendasPorStatus.vue'),
      //   meta: {
      //     precisaAutenticacao: true,
      //     titulo: 'Vendas',
      //     pagAnterior: '/geral-vendas',
      //     proxPagina: '/pontuacao',
      //     funcao: 'buscarVendasPorStatus',
      //   },
      // },
      // {
      //   path: '/pontuacao',
      //   name: 'pontuacao',
      //   component: () => import(/* webpackChunkName: "pontuacao" */ '@/views/pontuacao/Pontuacao.vue'),
      //   meta: {
      //     precisaAutenticacao: true,
      //     titulo: 'Pontuação',
      //     pagAnterior: '/vendas-por-status',
      //     proxPagina: '/produtividade',
      //     funcao: 'buscarPontuacaoMes,buscarPontuacaoRevenda',
      //   },
      // },
      // {
      //   path: '/produtividade',
      //   name: 'produtividade',
      //   component: () => import(/* webpackChunkName: "produtividade" */ '@/views/produtividade/Produtividade.vue'),
      //   meta: {
      //     precisaAutenticacao: true,
      //     titulo: 'Produtividade',
      //     pagAnterior: '/pontuacao',
      //     proxPagina: '/atendimento-ligacoes',
      //     funcao: 'buscarProdutividade',
      //   },
      // },
      // {
      //   path: '/atendimento-ligacoes',
      //   name: 'atendimento-ligacoes',
      //   component: () => import(/* webpackChunkName: "atendimento" */ '@/views/atendimento/Atendimento.vue'),
      //   meta: {
      //     precisaAutenticacao: true,
      //     titulo: 'Atendimento',
      //     pagAnterior: '/produtividade',
      //     proxPagina: '/atendimento-ocorrencias',
      //     funcao: 'buscarAtendimento',
      //   },
      // },
      // {
      //   path: '/atendimento-ocorrencias',
      //   name: 'atendimento-ocorrencias',
      //   component: () => import(/* webpackChunkName: "atendimento" */ '@/views/atendimento/AtendimentoOcorrencias.vue'),
      //   meta: {
      //     precisaAutenticacao: true,
      //     titulo: 'Atendimento',
      //     pagAnterior: '/atendimento-ligacoes',
      //     proxPagina: '/campanhas',
      //     funcao: 'buscarAtendimentoOcorrencias',
      //   },
      // },
      // {
      //   path: '/campanhas',
      //   name: 'campanhas',
      //   component: () => import(/* webpackChunkName: "campanhas" */ '@/views/campanhas/Campanhas.vue'),
      //   meta: {
      //     precisaAutenticacao: true,
      //     titulo: 'Campanhas',
      //     pagAnterior: '/atendimento-ocorrencias',
      //     proxPagina: '/relatorios',
      //   },
      // },
      // {
      //   path: '/campanhas/aniversario/:id',
      //   name: 'campanhas-aniversario',
      //   component: () => import(/* webpackChunkName: "campanhaAniversario" */ '@/views/campanhas/aniversario/Aniversario.vue'),
      //   meta: {
      //     precisaAutenticacao: true,
      //     titulo: 'Campanhas',
      //     navegacaoCampanha: true,
      //     etapa: 1,
      //     proxEtapa: '/campanhas/aniversario-engajamento/',
      //   },
      // },
      // {
      //   path: '/campanhas/aniversario-engajamento/:id',
      //   name: 'campanhas-aniversario-engajamento',
      //   component: () => import(/* webpackChunkName: "campanhaAniversario" */ '@/views/campanhas/aniversario/AniversarioEngajamento.vue'),
      //   meta: {
      //     precisaAutenticacao: true,
      //     titulo: 'Campanhas',
      //     navegacaoCampanha: true,
      //     etapa: 'final',
      //     etapaAnterior: '/campanhas/aniversario/',
      //   },
      // },
      // {
      //   path: '/campanhas/sazonal/:id',
      //   name: 'campanhas-sazonal',
      //   component: () => import(/* webpackChunkName: "campanhaSazonal" */ '@/views/campanhas/sazonal/Sazonal.vue'),
      //   meta: {
      //     precisaAutenticacao: true,
      //     titulo: 'Campanhas',
      //     navegacaoCampanha: true,
      //     etapa: 1,
      //     proxEtapa: '/campanhas/sazonal-desempenho/',
      //   },
      // },
      // {
      //   path: '/campanhas/sazonal-desempenho/:id',
      //   name: 'campanhas-sazonal-desempenho',
      //   component: () => import(/* webpackChunkName: "campanhaSazonal" */ '@/views/campanhas/sazonal/SazonalDesempenho.vue'),
      //   meta: {
      //     precisaAutenticacao: true,
      //     titulo: 'Campanhas',
      //     navegacaoCampanha: true,
      //     etapa: 'final',
      //     etapaAnterior: '/campanhas/sazonal/',
      //   },
      // },
      // {
      //   path: '/campanhas/anual/:id',
      //   name: 'campanhas-anual',
      //   component: () => import(/* webpackChunkName: "campanhaAnual" */ '@/views/campanhas/anual/Anual.vue'),
      //   meta: {
      //     precisaAutenticacao: true,
      //     titulo: 'Campanhas',
      //     navegacaoCampanha: true,
      //     etapa: 1,
      //     proxEtapa: '/campanhas/anual-ranking/',
      //   },
      // },
      // {
      //   path: '/campanhas/anual-ranking/:id',
      //   name: 'campanhas-anual-ranking',
      //   component: () => import(/* webpackChunkName: "campanhaAnual" */ '@/views/campanhas/anual/AnualRanking.vue'),
      //   meta: {
      //     precisaAutenticacao: true,
      //     titulo: 'Campanhas',
      //     navegacaoCampanha: true,
      //     etapa: 'final',
      //     etapaAnterior: '/campanhas/anual/',
      //   },
      // },
      // {
      //   path: '/relatorios',
      //   name: 'relatorios',
      //   component: () => import(/* webpackChunkName: "relatorios" */ '@/views/relatorios/Relatorios.vue'),
      //   meta: {
      //     precisaAutenticacao: true,
      //     titulo: 'Relatórios',
      //     pagAnterior: '/campanhas',
      //     proxPagina: '/power-bi',
      //   },
      // },
      // {
      //   path: '/power-bi',
      //   name: 'power-bi',
      //   component: () => import(/* webpackChunkName: "relatorios" */ '@/views/power-bi/Power-Bi.vue'),
      //   meta: {
      //     precisaAutenticacao: true,
      //     titulo: 'Power-BI',
      //     pagAnterior: '/relatorios',
      //   },
      // },
    ],
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  linkExactActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes,
});

function fecharElementosTela() {
  if (store.state.alerta.alertaAtivado)
    store.commit('SET_ALERTA', {
      alertaAtivado: false,
      mensagemAlerta: '',
      tipoAlerta: '',
    });
  if (store.state.menuAtivado) store.commit('SET_MENU', false);
}

function semValidacao(to) {
  return to.meta.precisaAutenticacao == true;
}

function verificarPermissoes(to) {
  if ((to.meta.precisaAutenticacao && !store.state.user.estaLogado) || (to.meta.perfilNaoPermitido && to.meta.perfilNaoPermitido.some((item) => item == store.state.user.perfilUsuario))) {
    return true;
  } else {
    return false;
  }
}

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');
  fecharElementosTela();
  if (token && !store.state.paginaAtualizada && semValidacao(to) && !store.state.user.estaLogado) {
    return buscarUsuario(token).then(() => {
      if (verificarPermissoes(to, from)) {
        return next('/login');
      }
      store.commit('SET_PAGINA_ATUALIZADA', true);
      next();
    });
  }
  if (verificarPermissoes(to)) {
    return next('login');
  }
  next();
});

export default router;
